<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        Editar ficha de mantenimiento
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editMaintenance">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <!-- name -->
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre'" label-for="maintenance-name">
                  <b-form-input
                    v-model="maintenance.name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>

              <!-- client -->
              <validation-provider
                v-if="selectClients.length > 0"
                #default="{ errors }"
                :name="'Cliente'"
                rules="required"
              >
                <b-form-group :label="'Cliente'" label-for="client">
                  <v-select
                    v-model="client"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectClients"
                    :placeholder="'Cliente'"
                    disabled
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>

              <!-- selectProjects -->
              <validation-provider
                v-if="selectProjects.length > 0"
                #default="{ errors }"
                :name="'Cliente'"
                rules="required"
              >
                <b-form-group :label="'Obra'" label-for="project">
                  <v-select
                    ref="projects"
                    v-model="project"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectProjects"
                    :placeholder="'Obra'"
                    disabled
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>

              <!-- date -->
              <b-form-group :label="'Fecha'" label-for="date">
                <flat-pickr
                  v-model="date"
                  name="date"
                  class="form-control"
                  :config="dateConfig"
                />
              </b-form-group>

              <!-- frequency -->
              <validation-provider
                v-if="frequencySelect.length > 0"
                #default="{ errors }"
                :name="'frequency_type'"
              >
                <b-form-group :label="'Frecuencia'" label-for="frequency_type">
                  <v-select
                    v-model="frequency_type"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="frequencySelect"
                    :placeholder="'Frecuencia'"
                  />
                </b-form-group>
              </validation-provider>
              <!-- responsable id -->
              <validation-provider #default="{ errors }" :name="'Responsable'"  :rules="maintenance.status.id === 5 ? 'required' : ''">
                <b-form-group
                  :label="'Responsable de mantenimiento'"
                  label-for="project-user"
                >
                  <v-select
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectUsers"
                    :placeholder="'Responsable de mantenimiento'"
                    v-model="maintenance.user"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>

              <b-form-group
                v-if="selectAssets.length > 0"
                class="mt-2"
                label="Equipos disponibles en esta obra"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="checkbox"
                  v-model="assets"
                  :options="listAssets"
                  stacked
                  :aria-describedby="ariaDescribedby"
                  name="assets"
                  text-field="productNameWithIni"
                  value-field="id"
                >
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <!-- description -->
              <b-form-group
                :label="'Descripción'"
                label-for="maintenance-description"
              >
                <quill-editor v-model="maintenance.description" />
              </b-form-group>

              <b-form-group :label="'Informe final'" label-for="documents">
                <ImageDropzone
                  v-if="maintenancePrepare"
                  ref="documents"
                  :files="files"
                  max-files="1"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { config } from "@/shared/app.config";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckboxGroup,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      files: [],
      client: "",
      project: "",
      assets: [],
      date: "",
      required,
      frequency_type: "",
      maintenancePrepare: false,
      active: false,
      dateConfig: {
        enableTime: true,
        time_24hr: true,
      },
      frequencyText: config.frequencyText,
      frequencySelect: config.frequencySelect,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getUser",
      maintenance: "maintenances/getMaintenance",
      selectClients: "clients/getSelectClients",
      selectProjects: "projects/getItems",
      selectAssets: "assets/getItems",
      selectUsers: "users/getSelectUsers",
      // role: 'auth/getRole',
      // selectStatus: 'orders/getSelectStatus',
    }),
    listAssets() {
      // Devolver todos los assets de todos los tipos
      return this.selectAssets
      /* const filteredProducts = this.selectAssets.filter((asset) => {
        const categoryName = asset.category.name.toLowerCase();
        return categoryName.includes('cortina') || categoryName.includes('cortinas');
      });
      return filteredProducts */
    },
  },
  methods: {
    ...mapActions({
      edit: "maintenances/edit",
      getMaintenance: "maintenances/getMaintenance",
      getSelectClients: "clients/selectClients",
      getListProjects: "projects/getListProjects",
      getListAssets: "assets/getListAssets",
      searchUsers: "users/selectUsers",
    }),
    handleSubmit() {
      this.$refs.editMaintenance.validate().then((success) => {
        if (success) {
          const { id } = this.maintenance;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, maintenance: formData });
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      });
    },
    async setData() {
      if (this.maintenance.documents.length > 0) {
        const aux = this;
        this.maintenance.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/maintenances/delete_doc/${aux.maintenance.id}/${element.id}`,
          };
          this.files.push(file);
        });
      }

      //set client
      if (this.currentUser.clients.length > 0) {
        this.client = this.currentUser.clients[0];
      } else {
        this.getSelectClients();
        this.client = this.maintenance.client;
      }

      await this.getListProjects(this.client.id, 1, 9999);

      this.project = this.maintenance.project;

      this.date = TimestampToFlatPickrWithTime(this.maintenance.date);

      await this.getListAssets({
        id_client: this.client.id,
        project_id: this.project.id,
        page: 1,
        per_page: 9999,
      });

      var list = [];

      if (this.maintenance.assets.length > 0) {
        this.maintenance.assets.map(function (value, key) {
          list.push(value.id);
        });

        this.assets = list;
      }

      // this.frequency_type = this.frequencyText[this.maintenance.frequency_type]

      this.frequencySelect.filter((item) => {
        if (item.value == this.maintenance.frequency_type) {
          this.frequency_type = item;
        }
      });

      this.maintenancePrepare = true;
    },
    createFormData() {

      const data = this.$refs.documents.getFormData("documents");

      data.append("name", this.maintenance.name);
      if (this.maintenance.user) {
        data.append("user_id", this.maintenance.user.id);
      }

      if (
        this.maintenance.description !== "" ||
        this.maintenance.description !== null
      ) {
        data.append("description", this.maintenance.description);
      }
      data.append("project_id", this.project.id);
      data.append("date", FlatPickrToTimestamp(this.date));

      // data.append('status_id', this.maintenance.status.id)

      if (this.frequency_type) {
        data.append("frequency_type", this.frequency_type.value);
      } else {
        data.append("frequency_type", null);
      }

      this.assets.forEach((element) => data.append("assets[]", element));

      return data;
    },
  },
  async created() {
    await this.getMaintenance(this.$route.params.id);
    await this.setData();
    await this.searchUsers({
      page: 1,
      per_page: 999,
      search: "",
      roles: ["tec"],
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
